
import { defineComponent , ref } from "vue";
import axios from "@/api/axios";
import Toast from "@/components/UI/Toast";
export default defineComponent({
  name: "IntergralRule",
  setup(){
       const intergralData = ref(null);

     axios
      .get("/M/User/ExchangeProduct")
      .then((res) => {
        if (res.data.success) {
          intergralData.value = res.data.obj;
        } else {
          Toast({
            type: "error",
            title: res.data.msg,
          });
        }
      })
      .catch((err) => {
        console.log('err')
      });
      return {
        intergralData
      }
  }
});
